// Importing required libraries
//import _ from 'lodash-es';
// import axios from 'axios';
import Pusher from 'pusher-js';
import Echo from 'laravel-echo';

// Lodash setup
// window._ = _;

// Axios setup
// try {
//     window.axios = axios;
//     window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
//     console.log('Axios initialized successfully');
// } catch (error) {
//     console.error('Error initializing Axios:', error);
// }

// Pusher and Laravel Echo setup
try {
    window.Pusher = Pusher;

    // Check if keys are present before instantiating Pusher
    const pusherAppKey = import.meta.env.VITE_ABLY_PUBLIC_KEY;
    const pusherAppCluster = import.meta.env.VITE_PUSHER_APP_CLUSTER || 'mt1';

    if (pusherAppKey && pusherAppKey !== '') {
        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: pusherAppKey,
            cluster: pusherAppCluster,
            wsHost: 'realtime-pusher.ably.io',
            wsPort: 443,
            disableStats: true,
            encrypted: true,
        });

        console.log("Pusher and Echo initialized successfully");
    } else {
        console.warn('Pusher keys not provided. Echo will not be initialized.');
    }
} catch (error) {
    console.error('Error initializing Pusher and Echo:', error);
}

import jQuery from 'jquery';
window.jQuery = jQuery;

const $ = jQuery;

$(document).ready(function () {
    if (typeof $ !== 'undefined' && $.fn && $.fn.jquery) {
        console.log('jQuery is loaded using $ alias:', $.fn.jquery);
    } else {
        console.log('jQuery is not loaded under $ alias');
    }
});

Object.defineProperty(window, '$', {
    configurable: true,
    enumerable: true,
    get() {
        return window._dollarSign;
    },
    set(value) {
        console.trace('Setting `$` to:', value);
        window._dollarSign = value;
    }
});
