<template>
    <div class="mt-6 flex-auto rounded-t-2xl bg-white text-brand-black transition duration-300 ease-in-out">
        <div class="px-5 pt-6">
            <div class="relative w-full h-1 mt-4 rounded-lg" style="background-color: #E3E5E5">
                <div class="absolute w-7/12 h-1 bg-wonderful rounded-lg z-10">
                </div>
            </div>
        </div>
        <div class="flex items-center mt-4 px-5">
            <button @click="goBack()">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5"/>
                </svg>
            </button>
            <h1 class="flex-1 text-lg text-center text-brand-black">
                Link to your bank account
            </h1>
            <button>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="w-6 h-6">
                </svg>
            </button>
        </div>
        <h2 class="px-5 mt-6 text-2xl text-center text-medium text-brand-black">
            More information
        </h2>
        <p class="px-5 py-4 text-center text-brand-black">
            To use this service, Wonderful Payments Limited needs to access information from your account at
            {{ bank.display_name }}.
        </p>
        <div class="py-6 flex flex-col bg-brand-gray-light">
            <p class="px-5 uppercase text-xs text-brand-gray">Your data</p>
            <div class="mt-2 py-4 px-5 bg-white">
                <button @click="showWhyShareData = !showWhyShareData"
                        class="w-full flex justify-between items-center">
                    <div class="text-left">
                        <p class="text-rand-black">Why we need you to share your data</p>
                        <p class="text-sm text-brand-gray">Tap for more information</p>
                    </div>
                    <svg
                        v-if="!showWhyShareData"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-6 h-6 text-brand-black">
                        <path stroke-linecap="round" stroke-linejoin="round"
                              d="M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5"/>
                    </svg>
                    <svg
                        v-if="showWhyShareData"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-6 h-6 text-brand-black">
                        <path stroke-linecap="round" stroke-linejoin="round"
                              d="M4.5 12.75l7.5-7.5 7.5 7.5m-15 6l7.5-7.5 7.5 7.5"/>
                    </svg>
                </button>
                <p class="py-4 text-sm text-brand-gray" v-if="showWhyShareData">
                    Sharing your account data in this way allows Wonderful Payments to retrieve information securely
                    through your bank, in order to provide you with the services it offers.
                </p>
            </div>
        </div>
        <div
            class="text-brand-gray flex flex-col bg-brand-gray-light">
            <p class="text-xs px-5 uppercase">What we need you to share</p>
            <div class="mt-2 py-4 px-5 bg-white">
                <button @click="showAccountDetailsDropdown = !showAccountDetailsDropdown"
                        class="w-full flex justify-between items-center">
                    <div class="text-left">
                        <p class="text-brand-black">Your account details</p>
                        <p class="text-sm text-brand-gray">Tap for more information</p>
                    </div>
                    <svg
                        v-if="!showAccountDetailsDropdown"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-6 h-6 text-brand-black">
                        <path stroke-linecap="round" stroke-linejoin="round"
                              d="M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5"/>
                    </svg>
                    <svg
                        v-if="showAccountDetailsDropdown"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-6 h-6 text-brand-black">
                        <path stroke-linecap="round" stroke-linejoin="round"
                              d="M4.5 12.75l7.5-7.5 7.5 7.5m-15 6l7.5-7.5 7.5 7.5"/>
                    </svg>
                </button>
                <ul class="mt-4 px-6 list-disc text-brand-gray text-sm" v-if="showAccountDetailsDropdown">
                    <li>Accountholder name</li>
                    <li>Account number</li>
                    <li>Sort code</li>
                    <li>Account type</li>
                    <li>Address information</li>
                </ul>
            </div>
            <div class="py-4 px-5 bg-white"
                 v-if="bank.ais_supported_resources && bank.ais_supported_resources.party"
            >
                <button @click="showPartyDetailsDropdown = !showPartyDetailsDropdown"
                        class="w-full flex justify-between items-center">
                    <div class="text-left">
                        <p class="text-brand-black">Contact and party details</p>
                        <p class="text-sm text-brand-gray">Tap for more information</p>
                    </div>
                    <svg
                        v-if="!showPartyDetailsDropdown"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-6 h-6 text-brand-black">
                        <path stroke-linecap="round" stroke-linejoin="round"
                              d="M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5"/>
                    </svg>
                    <svg
                        v-if="showPartyDetailsDropdown"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-6 h-6 text-brand-black">
                        <path stroke-linecap="round" stroke-linejoin="round"
                              d="M4.5 12.75l7.5-7.5 7.5 7.5m-15 6l7.5-7.5 7.5 7.5"/>
                    </svg>
                </button>
                <ul class="mt-4 px-6 list-disc text-brand-gray text-sm" v-if="showPartyDetailsDropdown">
                    <li>Party name(s)</li>
                    <li>Party address(es)</li>
                    <li>Party telephone number(s)</li>
                </ul>
            </div>
        </div>
        <form class="px-5 py-8 bg-white" :action="formUrl" method="POST">
            <input type="hidden" name="_token" :value="csrfToken">
            <input type="hidden" name="external_reference" :value="externalReference">
            <input type="hidden" name="bank" :value="bank.name">
            <input type="hidden" name="scope" value="accounts">
            <button
                class="w-full rounded-full bg-wonderful py-2.5 px-4 text-sm text-white">
                Enable
            </button>
            <button
                @click="goBack()"
                type="button"
                class="mt-2 w-full text-center rounded-full bg-white py-2.5 px-4 text-sm text-wonderful"
            >
                Back
            </button>
        </form>
    </div>
</template>
<script>
export default {
    name: "BankConfirmation",
    data() {
        return {
            showWhyShareData: false,
            showAccountDetailsDropdown: false,
            showPartyDetailsDropdown: false,
        }
    },
    props: {
        formUrl: {
            type: String,
            required: true
        },
        bank: {
            type: Object,
            required: true
        },
        externalReference: {
            type: String,
            required: true
        },
        csrfToken: {
            type: String,
            required: true
        }
    },
    methods: {
        goBack() {
            this.$emit('go-back');
        }
    }
}
</script>

<style scoped>

</style>
