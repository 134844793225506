<template>
    <div class="mx-auto">
        <div class="text-white flex flex-col">
            <!-- Bank Selector -->
            <div class="flex-auto rounded-t-2xl bg-white transition duration-300 ease-in-out">
                <h1 class="text-2xl text-black text-center">Please select your bank</h1>
                <div class="px-5 flex flex-col max-h-full">
                    <bank-list
                        @bank-selected="bankSelectedHandler"
                        :banks="banks"
                        :wonderfulPaymentsId="wonderfulPaymentsId"
                    />
                    <div class="block w-full mt-4 text-center text-gray-600">
                        <a
                            class="my-2 underline"
                            :href="suggestBankUrl"
                        >
                            <span class="">My bank isn't listed</span>
                        </a>
                        <span> | </span>
                        <a :href="paymentCancelUrl"
                           class="my-2 underline">Cancel
                        </a>
                    </div>
                    <form :action="paymentConsentUrl" method="POST" ref="bankForm">
                        <input type="hidden" name="_token" :value="csrfToken">
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FcaFooterText from "./FcaFooterText.vue";
import BankList from "../components/BankList.vue";
import BankSearch from "../components/BankSearch.vue";

export default {
    name: "BankSelectionPage",
    components: {BankSearch, BankList, FcaFooterText},
    props: {
        searchApiUrl: String,
        paymentCancelUrl: String,
        termsUrl: String,
        paymentRequest: Object,
        csrfToken: String,
        testing: String,
        suggestBankUrl: String,
        agreed: Boolean,
    },
    data() {
        return {
            selectedBank: '',
            banks: [],
            wonderfulPaymentsId: this.paymentRequest.wonderfulPaymentsId,
        }
    },
    methods: {
        getApiData() {
            let url = this.searchApiUrl;
            if (this.testing === 'true') {
                if (url.indexOf('?') > -1) {
                    url += '&testing=true'
                } else {
                    url += '?testing=true'
                }
            }
            if (this.scope && this.scope.length > 0) {
                if (url.indexOf('?') > -1) {
                    url += '&scope=' + this.scope
                } else {
                    url += '?scope=' + this.scope
                }
            }
            fetch(`${url}`)
                .then(res => res.json())
                .then(data => {
                    this.banks = data.data;

                    // Filter out "mettle" if paymentType is "domestic-standing-orders"
                    if (this.paymentRequest.paymentType === "domestic-standing-orders") {
                        this.banks = this.banks.filter(bank => bank.name !== "mettle");
                    }
                });
        },
        bankSelectedHandler(bank) {
            let wonderfulPaymentsId = this.paymentRequest.wonderfulPaymentsId
            let bankName = bank.name
            this.$refs.bankForm.action = `/payments/${wonderfulPaymentsId}/${bankName}/consent`
            this.$refs.bankForm.submit();
        },
    },
    created() {
        this.getApiData()
    },
}
</script>
