<template>
    <bank-list
        @bank-selected="bankSelectedHandler"
        :banks="filteredBanks"
        :wonderfulPaymentsId="wonderfulPaymentsId"
        :csrfToken="csrfToken"
    />
</template>

<script>
import BankList from "./BankList.vue";

export default {
    name: "BankSearch",
    components: {BankList},
    props: {
        searchApiUrl: String,
        testing: String,
        scope: String,
        wonderfulPaymentsId: String,
        csrfToken: String,
    },
    data() {
        return {
            searchText: '',
            banks: [],
        }
    },
    created() {
        this.getApiData()
    },
    methods: {
        getApiData() {
            let url = this.searchApiUrl;
            if (this.testing === 'true') {
                if (url.indexOf('?') > -1) {
                    url += '&testing=true'
                } else {
                    url += '?testing=true'
                }
            }
            if (this.scope && this.scope.length > 0) {
                if (url.indexOf('?') > -1) {
                    url += '&scope=' + this.scope
                } else {
                    url += '?scope=' + this.scope
                }

            }
            fetch(`${url}`)
                .then(res => res.json())
                .then(data => this.banks = data.data)
        },
        bankSelectedHandler(bank) {
            if (bank) {
                // emit bank details.
                this.$emit('bank-selected', bank);
            }},
    },
    computed: {
        filteredBanks() {
            return this.banks.filter(bank => bank.display_name.toUpperCase().indexOf(this.searchText.toUpperCase()) > -1)
        }
    }
}
</script>

