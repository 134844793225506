<template>
    <!-- Logo Image -->
    <div class="flex justify-center pt-4 px-4">
        <a href="/">
            <svg class="h-12" viewBox="0 0 315 115" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M304.631 89.9882C305.854 92.4142 311.121 90.7827 314.769 91.9957C314.928 100.359 303.678 96.7567 302.224 97.0356C300.531 97.5997 297.048 100.675 293.706 102.573C289.428 104.392 288.206 106.212 282.957 104.58C279.621 102.967 278.9 101.354 274.745 98.3093C270.504 102.427 264.638 109.323 256.608 105.387C252.82 97.9514 259.859 85.9793 256.248 79.8537C238.014 77.3489 253.449 111.209 243.159 114.612C235.399 114.612 241.534 94.549 239.462 84.6025C236.028 84.4267 230.528 92.7538 227.999 90.3339C223.666 80.3935 237.201 78.6043 241.717 73.0489C242.89 63.0175 251.372 40.2618 257.873 38.9215C260.666 38.4727 263.556 40.4438 266.428 46.0842C266.789 47.2486 267.039 48.5951 266.428 49.8505C264.98 51.015 262.725 48.5041 260.287 48.5041C256.767 48.5041 251.805 62.9265 250.003 70.8109C266.697 68.6578 266.697 81.467 264.534 95.5315C264.442 95.7135 264.35 97.1448 264.98 97.2358C274.366 96.0713 271.659 83.7049 276.174 77.7067C276.743 77.2061 277.487 76.9489 278.246 76.9911C285.652 78.8712 280.324 87.6471 281.68 92.6628C284.662 97.3814 290.369 99.8922 294.628 93.8758C297.219 88.5448 291.353 79.1199 289.783 71.757C286.281 60.9615 292.227 23.6803 306.892 36.4895C318.154 44.1616 303.91 78.3435 304.631 89.9882ZM301.002 42.8212C292.826 50.6389 295.368 71.5084 299.78 81.4488C302.493 71.3264 312.991 44.9561 301.002 42.8212Z"
                    fill="white"/>
                <path
                    d="M173.761 101.536C170.602 106.461 164.461 100.996 160.672 100.189C153.993 102.7 148.579 109.596 142.805 109.238C137.843 107.625 137.476 106.461 135.222 104.308C122.591 114.254 115.552 97.3207 114.647 85.2333C108.329 93.0207 103.905 111.118 98.1487 111.118C89.8444 109.772 98.4176 69.6464 77.5744 77.7067C80.3669 84.5116 89.4839 88.2779 88.5734 101.536C87.8646 112.089 75.839 116.85 67.2658 111.482C61.3996 107.807 51.5616 88.4356 65.2799 81.7642C64.4672 80.2358 63.4467 78.9864 63.9233 76.3057C84.5954 62.3564 96.5966 68.1545 100.116 94.8462C106.807 87.8291 107.418 75.8266 116.725 71.4538C127.822 71.096 112.839 94.8341 133.957 102.27C135.497 86.234 136.67 70.5562 153.816 70.1256C156.975 64.746 154.989 51.4941 161.393 51.0453C173.126 50.2387 162.206 84.8997 165.365 93.3239C166.807 96.9629 173.761 98.4003 173.761 101.536ZM141.986 98.934C144.601 104.308 152.906 98.5762 156.609 96.0713C155.16 90.249 155.882 82.9893 152.545 79.0471C146.862 80.6604 141.363 90.158 141.992 98.934H141.986ZM68.3474 85.949C65.1882 93.0207 65.011 102.876 72.7714 105.029C89.5572 109.772 80.3486 81.9825 68.1702 82.8134C67.9868 83.1712 68.7996 84.8694 68.3474 85.949Z"
                    fill="white"/>
                <path
                    d="M5.17617 80.2116C8.15202 70.3621 9.14805 62.9265 14.3787 63.2844C22.4141 63.8241 11.6717 84.6026 8.96473 96.0714C11.3112 98.4003 17.3607 81.2002 26.111 81.7399C32.521 82.0978 35.1364 108.074 47.4125 105.563C51.7449 103.058 37.935 73.5887 41.3019 67.7663C51.861 62.2109 60.3486 109.614 51.9527 114.254C41.2164 117.39 31.1034 101.263 24.7911 90.5159C22.3469 90.5159 15.8514 109.238 2.86026 106.194C-1.961 102.252 1.47315 92.578 5.17617 80.2116Z"
                    fill="white"/>
                <path
                    d="M183.324 94.7249C183.361 95.3914 183.532 96.0439 183.827 96.6439C184.122 97.2438 184.535 97.7792 185.041 98.2183C192.71 104.132 205.524 92.305 207.424 84.4267C209.496 83.8869 209.41 85.5911 211.573 85.1423C214.28 78.2465 220.415 62.9265 228.72 72.1512C228.989 73.4977 227.546 74.0314 226.917 74.4802C219.584 80.6603 216.987 83.529 218.161 104.665C211.39 105.205 210.04 101.979 206.068 98.0424C199.212 101.263 195.6 105.927 189.569 106.909C158.686 111.652 181.699 48.3282 198.466 71.4538C202.004 86.1248 186.936 85.3182 183.324 94.7249ZM185.854 81.3821C188.738 80.3995 190.907 78.7863 190.907 75.6507C187.388 75.9176 187.119 77.7977 185.854 81.376V81.3821Z"
                    fill="white"/>
                <path
                    d="M243.972 15.99C238.851 19.2369 233.479 22.0761 227.907 24.4809C230.078 18.3149 232.154 12.1388 234.134 5.95255C235.967 0.494113 227.278 -1.84089 225.469 3.52658C222.762 11.6778 220.055 19.9019 217.079 28.0713C213.199 19.8291 209.496 11.32 204.442 3.88441C201.198 -0.864431 193.443 3.61755 196.682 8.36639C199.614 12.8116 202.237 17.4499 204.534 22.249C203 21.2604 201.479 20.3689 200.024 19.3803C195.056 16.3478 190.547 24.0382 195.509 27.0828C198.759 29.0539 201.919 31.1159 205.163 33.087C196.951 35.8648 188.665 38.4606 180.525 41.4142C175.112 43.3853 177.47 52.0763 182.97 50.0143C192.624 46.5209 202.554 43.3853 212.301 39.9829C209.442 46.797 206.063 53.3845 202.194 59.6878C199.138 64.6186 206.978 69.0945 209.954 64.1698C213.021 59.1677 215.794 53.9938 218.258 48.6739C220.438 52.1162 222.89 55.3813 225.591 58.4384C229.471 62.7324 235.881 56.3764 232.001 52.0763C229.76 49.6255 227.736 46.9876 225.952 44.1919C229.884 45.9858 233.922 47.5413 238.044 48.8498C243.642 50.6693 245.988 41.954 240.489 40.2497C235.712 38.7316 231.06 36.8499 226.575 34.6215C234.232 31.5537 241.594 27.8066 248.573 23.4256C253.358 20.6479 248.848 12.8544 243.972 15.99Z"
                    fill="#758FD3"/>
            </svg>
        </a>
    </div>
</template>

<script>
export default {
    name: "WonderfulLogo"
}
</script>

<style scoped>

</style>
