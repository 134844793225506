<template>
    <div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-7 xl:grid-cols-9 items-start gap-4 mt-4">
        <button
            class="border border-gray-300 sm:border-none  hover:shadow-md hover:border-blue-500 sm:hover:shadow-none sm:hover:border-none"
            v-for="(bank, key) in banks"
            :key="key"
            @click="selectBank(bank)"
        >
            <span class="flex items-center sm:flex-col">
                <span class="sm:rounded sm:border sm:border-gray-300 sm:hover:shadow-md sm:hover:border-blue-500 ">
                    <img :src="bank.logo" alt="" class="h-[4.5rem] w-[4.5rem] min-w-[4.5rem] my-2 mx-2">
                </span>
                <span class="text-left sm:text-center text-gray-600 overflow-hidden pr-4 sm:pr-0 sm:pt-1" style="font-family: paralucent, sans-serif; line-height: 0.1px">
                    <span class="text-[1.35rem] leading-8 sm:text-[0.65rem] sm:leading-[12px]" v-text="bank.status === 'offline' ? bank.display_name + ' (offline)' : bank.display_name"></span>
                    <i
                        v-if="bank.status === 'issues'"
                        class="fas fa-exclamation-triangle text-warning"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="This bank may be experiencing issues"
                    ></i>
                    <i
                        v-if="bank.status === 'offline'"
                        class="fas fa-exclamation-square text-muted"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="This bank is currently offline"
                    ></i>
                </span>
            </span>
        </button>
    </div>
</template>
<script>
export default {
    name: 'bank-list',
    props: {
        banks: {
            type: Array,
            default: () => [],
        },
        wonderfulPaymentsId: String,
        csrfToken: String,
    },
    data() {
        return {
            selectedBank: '',
        };
    },
    methods: {
        selectBank(bank) {
            if (bank) {
                // emit bank details.
                this.$emit('bank-selected', bank);
            }},
    },
}
</script>
<style>

.list-group-item > span {
    font-weight: 600;
    margin-left: 16px;
    color: #181715;
}

.list-group-item > span.suggest-a-bank {
    margin-left: 0;
    padding: 10px 0;
    width: 100%;
    text-align: center;
}

.list-group-item-action.bg-success > span {
    color: #fff;
}
</style>
