// Import global dependencies first
import './global-dependencies';  // Load global dependencies like lodash, axios, pusher, etc.

// Import required libraries for Vue setup
import { createApp } from 'vue';

// Create Vue application instance
const app = createApp({
    mounted() {
        console.log('Root Vue instance has mounted');
    },
    unmounted() {
        console.log('Root Vue instance has unmounted');
    }
});


// Import and register all Vue components dynamically from both components and pages directories
const components = import.meta.glob('./components/**/*.vue', { eager: true });
// const pages = import.meta.glob('./pages/**/*.vue', { eager: true });

// Only import BankSelectionPage.vue eagerly
const pages = import.meta.glob('./pages/BankSelectionPage.vue', { eager: true });


// Combine both imports into a single object
const allComponents = { ...components, ...pages  };

for (const path in allComponents) {
    const component = allComponents[path].default;
    const componentName = path.split('/').pop().replace('.vue', '');
    app.component(componentName, component);
}

// Mount the Vue application to the DOM element with ID 'app'
try {
    app.mount('#app');
    console.log('Vue app mounted successfully');
} catch (error) {
    console.error('Error mounting Vue app:', error);
}

// Import Bootstrap's dependencies
//import 'popper.js';
import 'bootstrap'; // Bootstrap's JS
